import UserProfile from "views/UserProfile";
import Publishers from "views/Publishers";
import Publisher from "views/Publisher";
import Graphs from "./views/Graphs";
import TotalActivity from "./views/TotalActivity";
import AllInstalls from "./views/AllInstalls";
import DailyUsers from "views/DailyUsers";
import Uniques from "./views/Uniques";
import Discover from "./views/Discover";
import DailyUsersAdmin from "./views/DailyUsersAdmin";
import ActiveIpsGms from "./views/ActiveIpsGms";
import ActiveIps from "./views/ActiveIPs/ActiveIpsMain";
import Dashboard from "./views/Dashboard";
// import SdkIntegration from "./views/SdkIntegration";
import MyEarnings from "views/MyEarnings";
import PublishersPayments from "views/PublishersPayments";
// import Audit from "views/Audit";

import { ShowOnlyInstall } from "utils/config";
import UniqueIps from "views/UniqueIps/UniqueIpsMain";

var routes = [
  {
    permissions: ["admin", "user"],
    path: "/dashboard",
    name: "Dashboard",
    icon: "home",
    component: Dashboard,
    layout: "/admin",
    meta: {
      hideToIds: [...ShowOnlyInstall],
    },
  },
  // {
  //   permissions: ["admin", "user"],
  //   path: "/Integration",
  //   name: "SDK Integration",
  //   icon: "code",
  //   component: SdkIntegration,
  //   layout: "/admin",
  //   meta: {
  //     only_publishers_statues: [3],
  //     hideToIds: [...ShowOnlyInstall],
  //   },
  // },
  {
    permissions: ["admin"],
    path: "/daily-users-admin/mobile",
    name: "Daily Users - Mobiles",
    icon: "users",
    component: DailyUsersAdmin,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/daily-users-admin/streamer",
    name: "Daily Users - Streamers",
    icon: "users",
    component: DailyUsersAdmin,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/daily-users-admin/gms",
    name: "Daily Users - Gms",
    icon: "users",
    component: DailyUsersAdmin,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/actives-ips/gms",
    name: "Active ips - Gms",
    icon: "globe",
    component: ActiveIpsGms,
    layout: "/admin",
  },
  {
    permissions: ["user"],
    path: "/daily-users",
    name: "Daily Users",
    icon: "users",
    component: DailyUsers,
    layout: "/admin",
    meta: {
      hideToIds: [...ShowOnlyInstall],
    },
  },

  {
    permissions: ["admin", "user"],
    path: "/uniques",
    name: "Unique Mobile",
    icon: "mobile",
    component: Uniques,
    layout: "/admin",
    meta: {
      only_publishers_ids: [135],
      hideToIds: [...ShowOnlyInstall],
    },
  },
  {
    permissions: ["admin"],
    path: "/total-activity",
    name: "Total Activity",
    icon: "chart-line",
    component: TotalActivity,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/publishers",
    name: "Publishers ",
    icon: "users-cog",
    component: Publishers,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/uniqueIps",
    name: "Unique IPS",
    icon: "code-branch",
    component: UniqueIps,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/activeIps",
    name: "Active IPS",
    icon: "code-branch",
    component: ActiveIps,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/publisher/:id",
    name: "GetPublisher",
    icon: "users-cog",
    component: Publisher,
    layout: "/admin",
    hidden: true,
  },
  {
    permissions: ["admin"],
    path: "/graphs",
    name: "Graphs",
    icon: "chart-pie",
    component: Graphs,
    layout: "/admin",
    hidden: true, // not work with https
  },
  // {
  //   permissions: ["admin"],
  //   path: "/all-installs",
  //   name: "All Installs ",
  //   icon: "laptop",
  //   component: AllInstalls,
  //   layout: "/admin",
  // },
  {
    permissions: ["user"],
    path: "/installs",
    name: "Installs",
    icon: "laptop",
    component: AllInstalls,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/all-installs/mobile",
    name: "All installs - Mobiles",
    icon: "users",
    component: AllInstalls,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/all-installs/streamer",
    name: "All installs - Streamers",
    icon: "users",
    component: AllInstalls,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/discovery",
    name: "Libra vpn installs",
    icon: "sitemap",
    component: Discover,
    layout: "/admin",
  },
  {
    permissions: ["admin"],
    path: "/publishers-payments",
    name: "publishers payments",
    icon: "coins",
    component: PublishersPayments,
    layout: "/admin",
  },
  {
    permissions: ["admin", "user"],
    path: "/my-earnings",
    name: "My Earnings",
    icon: "search-dollar",
    component: MyEarnings,
    layout: "/admin",
  },
  // {
  //   permissions: ["admin"],
  //   path: "/audit",
  //   name: "Audit log",
  //   icon: "history",
  //   component: Audit,
  //   layout: "/admin",
  // },
  {
    permissions: ["admin", "user"],
    path: "/user-profile",
    // name: "User Profile",
    // icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/admin",
    hidden: true,
  },
];
export default routes;
