import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { PublisherService } from "../../services/PublisherService";

const SelectPublisher = () => {
  const [publishers, setPublishers] = useState([]);
  const selectedDiscoveryPublisher = useSelector(
    (state) => (state as any).publisherReducer.selectedDiscoveryPublisher
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      const data = await PublisherService.loadDiscoveryPublishers();
      const publishersArr = data.map((r: { id: number; name: string }) => {
        return {
          value: r.id,
          label: r.name,
        };
      });
      setPublishers(publishersArr);
      if (selectedDiscoveryPublisher) {
        setSelectedOption(selectedDiscoveryPublisher);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changePublisher = (elem: any) => {
    setSelectedOption(elem);
    dispatch({
      type: "SET_SELECTED_DISCOVERY_PUBLISHER",
      publisher: elem,
    });
  };

  return publishers && publishers.length > 0 ? (
    <>
      <div style={{ zIndex: 999, minWidth: "150px" }}>
        <Select
          options={publishers}
          value={selectedOption}
          onChange={changePublisher}
        />
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default SelectPublisher;
