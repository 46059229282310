import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Moment from "react-moment";
import { loadActivityPerUser } from "../actions/UsageActions";

import DataTable, { createTheme } from "react-data-table-component";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import FilterWrapper from "../components/Filters/FilterWrapper";
import DateRange from "../components/Filters/DateRange";

import {
  lastSevenDays,
  today,
  themeTable,
  formatNumber,
  paginationPerPage,
} from "../utils/heplers";
import SelectPublisher from "components/Global/SelectPublisher";

import { CSVLink } from "react-csv";
import Loading from "../components/Global/Loading";
interface IActivity {
  GB: number;
  date: string;
  minutes: number;
  precentage: number;
  publisher: string;
  row_id: number;
  total_daily_minutes: number;
}
createTheme("solarized", themeTable);

const TotalActivity = () => {
  const [fromDate, setFromDate] = useState(lastSevenDays());
  const [toDate, setToDate] = useState(today());

  const [loading, setLoading] = useState(true);
  const [publisher, setPublisher] = useState<any>({});

  const dispatch = useDispatch();

  const activity = useSelector((state) => (state as any).usageReducer.activity);

  const publishers = useSelector((state) => (state as any).publisherReducer.publishers);

  const changePublisher = (id: number) => {
    const p = publishers.filter((p: Publisher) => p.row_id === id)[0];
    setPublisher(p);
  };

  useEffect(() => {
    (async function () {
      dispatch(loadActivityPerUser({ fromDate, toDate, publisher_name: publisher.publisher_name }) as any);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, toDate, fromDate]);

  useEffect(() => {
    setLoading(true);
    if (activity && activity.length > 0) {
      setLoading(!loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, publisher]);

  const handleDate = async (e: { start: string; end: string }) => {
    await setFromDate(e.start);
    await setToDate(e.end);
  };

  let columns = [
    {
      name: "Date",
      selector: "date",
      sortable: true,
      cell: (row: IActivity) => (
        <div data-tag="allowRowEvents">
          <Moment format="D MMM YYYY">{row.date}</Moment>
        </div>
      ),
    },
    {
      name: "Publisher",
      selector: "publisher",
      sortable: true,
    },
    {
      name: "Minutes",
      selector: "minutes",
      sortable: true,
      format: (row: IActivity) => formatNumber(row.minutes),
    },
    {
      name: "Total Minutes",
      selector: "total_daily_minutes",
      sortable: true,
      format: (row: IActivity) => formatNumber(row.total_daily_minutes),
    },
    {
      name: "Activity %",
      selector: "precentage",
      sortable: true,
      format: (row: IActivity) => row.precentage.toFixed(2),
    },
    {
      name: "Used GB ⇅",
      selector: "GB; precentage",
      sortable: true,
      format: (row: IActivity) =>
        row.GB ? ((row.GB * row.precentage) / 100).toFixed(0) : 0,
    },
    {
      name: "Total GB",
      selector: "GB",
      sortable: true,
      format: (row: IActivity) => (row.GB ? formatNumber(row.GB) : 0),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Total activity</div>
                    {activity && activity.length > 0 ? (
                      <CSVLink
                        filename={"Activities.csv"}
                        data={activity}
                        style={{ marginLeft: "auto" }}
                      >
                        <Button color="success" size="sm">
                          <i className="fas fa-file-csv fa-2x"></i>
                        </Button>
                      </CSVLink>
                    ) : null}
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  <DateRange onChange={handleDate} />

                  <div className="ml-3">
                    <SelectPublisher
                      filters="streamer"
                      selected="All"
                      changed={(r: { label: string; value: number }) =>
                        changePublisher(r.value)
                      }
                    />
                  </div>
                </FilterWrapper>
                <Loading loading={loading} />
                {activity && activity.length ? (
                  <DataTable
                    fixedHeader
                    highlightOnHover
                    columns={columns}
                    data={activity}
                    pagination={true}
                    paginationPerPage={paginationPerPage}
                    theme="solarized"
                    overflowY={true}
                    overflowYOffset="100%"
                  />
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TotalActivity;
