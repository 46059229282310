import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import FilterWrapper from "../components/Filters/FilterWrapper";
import Loading from "../components/Global/Loading";
import ChangeCountry from "../components/Filters/ChangeCountry";
// import ChangeDevice from "../components/Filters/ChangeDevice";
import DateRange from "../components/Filters/DateRange";
import SelectPublisher from "components/Global/SelectPublisher";
import Switch from "../components/Filters/Switch";

import {
  lastSevenDays,
  today,
  themeTable,
  formatNumber,
  paginationPerPage,
  formatDate,
} from "../utils/heplers";
import $axios from "utils/axios";
import { useSelector } from "react-redux";
import PublisherName from "components/Global/PublisherName";
import ShowCountry from "components/Global/ShowCountry";

const AllInstalls = () => {
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => (state as any).userReducer.user);
  const selectedPublisher = useSelector(
    (state) => (state as any).publisherReducer.selectedPublisher
  );

  let [data, setData] = useState([]);
  let [cols, setCols] = useState([]);

  let type: string | undefined = window.location.pathname.split("/").pop();

  const [filters, setFilters] = useState({
    showDate: false,
    showPublisher: user.publisher_is_admin ? false : true,
    country: "All",
    publisher: user.publisher_is_admin ? null : user.publisher_name,
    showCountry: false,
    showPrice: false,
    fromDate: lastSevenDays(),
    toDate: today(),
    device: user.publisher_is_admin
      ? type
      : selectedPublisher.publisher_is_streamer
      ? "streamer"
      : "mobile",
  });

  const handleDate = async (e: { start: string; end: string }) => {
    let f = {
      ...filters,
      fromDate: e.start,
      toDate: e.end,
    };
    setFilters(f);
  };

  useEffect(() => {
    (async () => {
      getData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setLoading(true);
    let url: any = { ...filters };

    if (url.device !== "all") {
      url.device = filters.device === "mobile" ? 0 : 1;
    }
    const filtersUri: string = new URLSearchParams(url).toString();
    let res = await $axios.get("/users/allinstalls?" + filtersUri);

    if (res.data.success) {
      let datas = [];
      Object.keys(res.data.data.rows).forEach((d) => {
        if (res.data.data.rows[d] != null) {
          datas.push(res.data.data.rows[d]);
        }
      });
      setData(res.data.data.rows);

      let columns: ((prevState: never[]) => never[]) | any = [];
      Object.keys(res.data.data.columns).forEach((e) => {
        if (res.data.data.columns[e].type === "number") {
          columns.push({
            name: res.data.data.columns[e].name,
            selector: e,
            sortable: true,
            sortFunction: (a: any, b: any) => a.installs - b.installs,
            cell: (row: any) => {
              return formatNumber(row[e]);
            },
          });
        } else if (res.data.data.columns[e].type === "date") {
          columns.push({
            name: res.data.data.columns[e].name,
            selector: e,
            sortable: true,
            cell: (row: any) => formatDate(row[e]),
          });
        } else if (res.data.data.columns[e].type === "money") {
          columns.push({
            name: res.data.data.columns[e].name,
            selector: e,
            sortable: true,
            cell: (row: any) => `$ ${formatNumber(row[e], 0)}`,
          });
        } else if (res.data.data.columns[e].type === "publisherName") {
          if (user.publisher_is_admin === 1) {
            columns.push({
              name: res.data.data.columns[e].name,
              selector: e,
              sortable: true,
              grow: user.publisher_is_admin ? 2 : 1,
              cell: (row: any) => <PublisherName name={row[e]} />,
            });
          }
        } else if (
          res.data.data.columns[e].type === "country_code" &&
          user.publisher_is_admin
        ) {
          columns.push({
            name: res.data.data.columns[e].name,
            selector: e,
            sortable: true,
            cell: (row: any) => <ShowCountry country_code={row[e]} />,
          });
        } else {
          columns.push({
            name: res.data.data.columns[e].name,
            selector: e,
            sortable: true,
          });
        }
      });
      setCols(columns);
      setLoading(false);
    }
  };

  // const handleChangeCountry = (e: {
  //   target: { value: React.SetStateAction<string> };
  // }): void => {
  //   setChosenCountry(e.target.value);
  //   setLoading(true);
  // };

  const changeFilters = (field: string, val: string | boolean | number) => {
    let f = {
      ...filters,
      [field]: val,
    };
    setFilters(f);
  };

  // const columns = [
  //   {
  //     name: "Date",
  //     selector: "register_date",
  //     sortable: true,
  //     cell: (row: any) => <div>{formatDate(row.register_date)}</div>,
  //   },
  //   {
  //     name: "Publisher",
  //     selector: "publisher",
  //     sortable: true,
  //   },
  //   {
  //     name: "New installs",
  //     selector: "installs",
  //     sortable: true,
  //     format: (row: any) => formatNumber(row.installs),
  //   },
  // ];

  createTheme("solarized", themeTable);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>All installs</div>
                </div>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <FilterWrapper>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="d-flex align-items-center">
                    <DateRange onChange={handleDate} />
                    <Switch
                      isShow={filters.showDate}
                      onChange={(e: boolean) => changeFilters("showDate", e)}
                      text="Date"
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <Switch
                      isShow={filters.showCountry}
                      onChange={(e: boolean) => changeFilters("showCountry", e)}
                      text="Country"
                    />

                    {filters.showCountry && (
                      <ChangeCountry
                        selected={filters.country}
                        handleChange={(e: any) =>
                          changeFilters("country", e.target.value)
                        }
                        style={{
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </div>

                  <div className="d-flex align-items-center">
                    {user.publisher_is_admin === 1 && (
                      <Switch
                        isShow={filters.showPublisher}
                        onChange={(e: boolean) =>
                          changeFilters("showPublisher", e)
                        }
                        text="Publisher"
                      />
                    )}
                    {filters.showPublisher && user.publisher_is_admin === 1 && (
                      <div className="d-flex align-items-center">
                        {/* <ChangeDevice
                          showAll={true}
                          selected={filters.device}
                          handleChangeDevice={(d: any) =>
                            changeFilters("device", d)
                          }
                        /> */}
                        <div className="ml-3">
                          <SelectPublisher
                            filters={filters.device}
                            showGmsWithStreamer={true}
                            selected="All"
                            changed={(r: { label: string }) =>
                              changeFilters("publisher", r.label)
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="ml-3 ">
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => {
                          setFilters(filters);
                          getData();
                        }}
                      >
                        <i
                          className="fas fa-search"
                          style={{ fontSize: 22 }}
                        ></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </FilterWrapper>
              <Loading loading={loading} />
              {cols.length > 0 ? (
                <DataTable
                  fixedHeader
                  highlightOnHover
                  columns={cols}
                  data={data}
                  pagination={true}
                  paginationPerPage={paginationPerPage}
                  theme="solarized"
                  overflowY={true}
                  overflowYOffset="100%"
                />
              ) : (
                <div className="no-data">No data available</div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AllInstalls;
