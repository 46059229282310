import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import { formatNumber, formatNumberWithoutComma } from "utils/heplers";
import Box from "../components/Dashboard/Box";
import ChartMyUsages from "../components/Dashboard/ChartMyUsages";
import ChartMyEarning from "../components/Dashboard/ChartMyEarning";
import ChartGeographical from "../components/Dashboard/ChartGeographical";
import ChartMyInstalls from "../components/Dashboard/ChartMyInstalls";
import QuestionnaireModal from "../components/Dashboard/QuestionnaireModal";
import $axios from "utils/axios";
import Loading from "../components/Global/Loading";
import { publisherStatus } from "enums/Publisher";
import usePrevious from "utils/Hooks/UsePrevious";
import SelectPublisher from "components/Global/SelectPublisher";
import Publisher from "./Publisher";

const Installs = () => {
  console.log("Dashboard");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [questionnaireModal, setQuestionnaireModal] = useState(false);
  // const selectedPublisher = useSelector(
  //   (state) => state.publisherReducer.selectedPublisher
  // );

  const currentUser = useSelector((state) => (state as any).userReducer.user);

  const [publisher, setPublisher] = useState(currentUser);

  const [pullsByMonth, setPullsByMonth] = useState([]);
  const [pulls, setPulls] = useState<any>(0);

  const [dataByMonth, setDataByMonth] = useState<any[]>([]);
  const [dataGeo, setDataGeo] = useState<any[]>([]);
  const [activity, setActivity] = useState<any[]>([]);
  const [installByMonth, setInstallByMonth] = useState<any[]>([]);
  const [earnings, setEarnings] = useState([]);

  const [usageMonth, setUsageMonth] = useState<number>(3);
  const [geoMonth, setGeoMonth] = useState<number>(3);
  const [installMonth, setInstallMonth] = useState<number>(3);
  const [earningsMonth, setEarningsMonth] = useState<number>(3);

  /* Prev values */
  const prevUsageMonth: number = usePrevious(usageMonth);
  const prevGeoMonth: number = usePrevious<number>(geoMonth);
  const prevInstallMonth: number = usePrevious<number>(installMonth);
  const prevIearningsMonth: number = usePrevious<number>(earningsMonth);
  const prevPublisher: Publisher = usePrevious<Publisher>(publisher);

  const publishers = useSelector((state) => (state as any).publisherReducer.publishers);

  const changePublisher = (id: number): void => {
    const p = publishers.filter((p: Publisher) => p.row_id === id)[0];
    setPublisher(p);
  };

  const isChangedPublisher = prevPublisher
    ? prevPublisher.row_id !== publisher.row_id
    : true;

  const [data, setData] = useState({
    uniques: 0,
  });

  const [activityData, setActivityData] = useState({ minutes: 0 });
  const [installData, setInstallData] = useState({ installs: 0 });

  const onFinish = () => {
    // console.log("ss");
  };

  let thisMonthRequsts = 0;

  // const getStatus = async () => {
  // if (publisher.publisher_status === publisherStatus.INACTIVE) {
  //   return "Inactive";
  // } else if (publisher.publisher_status === publisherStatus.PENDING) {
  //   return "Pending";
  // } else if (publisher.publisher_status === publisherStatus.ACTIVE) {
  //   return "Active";
  // }
  //   if (!publisher.publisher_is_streamer) {
  //     let pullsUsers = await $axios.get(
  //       `publishers/${publisher.publisher_name}/pulls`
  //     );
  //     setPullsByMonth(pullsUsers.data.data);
  //   }
  // };

  useEffect(() => {
    // if (prevPublisher == undefined) {
    //   return;
    // }
    if (
      [publisherStatus.INACTIVE, publisherStatus.PENDING].includes(
        publisher.publisher_status
      )
    ) {
      const intervalId = setInterval(() => {
        $axios.get(`publishers/${publisher.row_id}`).then((data) => {
          dispatch({
            type: "SET_SELECTED_PUBLISHER",
            publisher: data.data.data,
          });
          if (currentUser.row_id === publisher.row_id) {
            dispatch({
              type: "SET_USER",
              user: data.data.data,
            });
          }
        });
      }, 1000 * 30);

      return () => clearInterval(intervalId); //This is important
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async function () {
      if (!publisher.publisher_is_streamer) {
        let pullsUsers = await $axios.get(
          `publishers/${publisher.publisher_name}/pulls`
        );
        setPullsByMonth(pullsUsers.data.data);
      }

      if (prevIearningsMonth !== earningsMonth || isChangedPublisher) {
        let earnings = await $axios.get(`earnings?latest=${earningsMonth}`, {
          params: {
            publisher: publisher.row_id,
          },
        });
        setEarnings(earnings.data.data);
      }

      if (prevUsageMonth !== usageMonth || isChangedPublisher) {
        let res = await $axios.get(
          `publishers/${publisher.publisher_name}/uniques?latest=${usageMonth}`
        );
        setDataByMonth(res.data.data);

        if (publisher.publisher_is_streamer || isChangedPublisher) {
          let activity = await $axios.get(
            `usages/${publisher.publisher_name}/minutes?latest=${usageMonth}`
          );
          setActivity(activity.data.data);
        }
      }

      if (prevInstallMonth !== installMonth || isChangedPublisher) {
        let installs = await $axios.get(
          `publishers/${publisher.row_id}/metrics?latest=${installMonth}`
        );
        setInstallByMonth(installs.data.data);
      }

      if (prevGeoMonth !== geoMonth || isChangedPublisher) {
        let geo = await $axios.get(
          `publishers/${publisher.row_id}/metrics-geo?latest=${geoMonth}`
        );
        setDataGeo(geo.data.data);
      }
    })();
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, usageMonth, geoMonth, installMonth, earningsMonth]);

  useEffect(() => {
    let uniques = 0;

    // if (dataByMonth) {
    //   Object.keys(dataByMonth).forEach((e: any) => {
    //     uniques += dataByMonth[e].uniques;
    //   });
    // }

    let activityKeys: any[] = Object.keys(dataByMonth);
    if (activityKeys && activityKeys.length > 0) {
      uniques = dataByMonth[activityKeys[activityKeys.length - 1]].uniques;
    }

    setData({
      uniques,
    });
    setLoading(false);
  }, [dataByMonth]);

  useEffect(() => {
    let p = 0;

    // if (dataByMonth) {
    //   Object.keys(dataByMonth).forEach((e: any) => {
    //     uniques += dataByMonth[e].uniques;
    //   });
    // }

    let activityKeys: any[] = Object.keys(pullsByMonth);
    if (activityKeys && activityKeys.length > 0) {
      p = pullsByMonth[activityKeys[activityKeys.length - 1]].pulls;
    }

    setPulls(p);
    // setLoading(false);
  }, [pullsByMonth]);

  useEffect(() => {
    let installs = 0;

    if (installByMonth) {
      let activityKeys: any[] = Object.keys(installByMonth);
      if (activityKeys && activityKeys.length > 0) {
        installs =
          installByMonth[activityKeys[activityKeys.length - 1]].total_installs;
      }

      // Object.keys(installByMonth).forEach((e: any) => {
      //   installs += installByMonth[e].total_installs;
      // });
    }

    setInstallData({
      installs,
    });
    setLoading(false);
  }, [installByMonth]);

  useEffect(() => {
    let minutes = 0;

    // if (activity) {
    //   Object.keys(activity).forEach((e: any) => {
    //     minutes += activity[e].total_daily_minutes;
    //   });
    // }

    let activityKeys: any[] = Object.keys(activity);
    if (activityKeys && activityKeys.length > 0) {
      minutes =
        activity[activityKeys[activityKeys.length - 1]].total_daily_minutes;
    }

    setActivityData({
      minutes,
    });
    setLoading(false);
  }, [activity]);

  if (dataByMonth || activity) {
    if (!publisher.publisher_is_streamer) {
      let dataKeys: any[] = Object.keys(dataByMonth);
      if (dataKeys && dataKeys.length > 0) {
        thisMonthRequsts = dataByMonth[dataKeys[dataKeys.length - 1]].uniques;
      }
    } else {
      if (activity) {
        let activityKeys: any[] = Object.keys(activity);
        if (activityKeys && activityKeys.length > 0) {
          thisMonthRequsts =
            activity[activityKeys[activityKeys.length - 1]]
              .total_daily_minutes / 60;
        }
      }
    }
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Dashboard</div>
                    <div
                      className="ml-3"
                      style={{ color: "#000000", width: 250 }}
                    >
                      {currentUser.publisher_is_admin === 1 && (
                        <SelectPublisher
                          selected={publisher.publisher_name}
                          changed={(r: { label: string; value: number }) =>
                            changePublisher(r.value)
                          }
                        />
                      )}
                    </div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Row className="d-flex justify-content-center">
                      {!publisher.publisher_is_streamer ? (
                        <Box
                          bg="dark"
                          title="TOTAL PULLS"
                          icon="user-alt"
                          value={formatNumber(pulls)}
                        />
                      ) : (
                        ""
                      )}
                      <Box
                        bg="dark"
                        title="Total Installs"
                        icon="laptop-code"
                        value={formatNumber(installData.installs)}
                      />
                      <Box
                        bg="dark"
                        title={
                          publisher.publisher_is_streamer
                            ? "This month Minutes"
                            : "This month Uniques"
                        }
                        icon="ethernet"
                        value={
                          publisher.publisher_is_streamer
                            ? formatNumber(activityData.minutes)
                            : formatNumber(data.uniques)
                        }
                      />
                      <Box
                        bg="dark"
                        title="Money Earned this month"
                        icon="money-bill-wave"
                        value={`${
                          publisher.publisher_is_streamer
                            ? formatNumberWithoutComma(
                                thisMonthRequsts * publisher.publisher_price
                              )
                            : formatNumberWithoutComma(
                                (pulls / 60) * publisher.publisher_price
                              )
                        }$`}
                      />
                    </Row>
                  </Col>
                </Row>

                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {publisher.publisher_status === publisherStatus.INACTIVE && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        background: "transparent",
                        // zIndex: "2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {!questionnaireModal && (
                        <Button
                          color="success"
                          size="lg"
                          style={{ position: "absolute", zIndex: 99 }}
                          onClick={() =>
                            setQuestionnaireModal(!questionnaireModal)
                          }
                        >
                          Join & Start Earning!
                        </Button>
                      )}
                    </div>
                  )}

                  {publisher.publisher_status === publisherStatus.PENDING && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        background: "transparent",
                        // zIndex: "2",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        fontSize: "20px",
                      }}
                    >
                      <i className="fas fa-hourglass-start  fa-3x	mr-3"></i>{" "}
                      Waiting for approval
                    </div>
                  )}

                  <div
                    style={{
                      opacity:
                        publisher.publisher_status ===
                          publisherStatus.INACTIVE ||
                        publisher.publisher_status === publisherStatus.PENDING
                          ? "0.5"
                          : 1,
                    }}
                  >
                    <Loading loading={loading} />
                    <>
                      <Row style={{ color: "#fff" }} className="mt-5">
                        <Col xs="12" xl="6">
                          <ChartMyEarning
                            onUpdate={(e: number) => setEarningsMonth(e)}
                            data={earnings}
                            numberMonth={earningsMonth}
                          />
                        </Col>
                        <Col xs="12" xl="6">
                          <ChartMyUsages
                            onUpdate={(e: number) => setUsageMonth(e)}
                            activity={activity}
                            data={dataByMonth}
                            numberMonth={usageMonth}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-5">
                        <Col xs="12" xl="6">
                          {dataGeo && Object.keys(dataGeo).length > 0 ? (
                            <ChartGeographical
                              onUpdate={(e: number) => setGeoMonth(e)}
                              data={dataGeo}
                              numberMonth={geoMonth}
                            />
                          ) : (
                            <div
                              style={{
                                color: "white",
                                textAlign: "center",
                                marginTop: "100px",
                              }}
                            >
                              No Data
                            </div>
                          )}
                        </Col>
                        <Col xs="12" xl="6">
                          <ChartMyInstalls
                            onUpdate={(e: number) => setInstallMonth(e)}
                            data={installByMonth}
                            numberMonth={installMonth}
                          />
                        </Col>
                      </Row>
                    </>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <QuestionnaireModal
          show={questionnaireModal}
          publisher={publisher}
          size="lg"
          onShowChange={(i: any) => {
            setQuestionnaireModal(i);
          }}
          onFinish={onFinish}
        />
      </div>
    </>
  );
};

export default Installs;
