import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import $axios from "../utils/axios";
import { Link } from "react-router-dom";
import { validateEmail } from "../utils/heplers";
import logo from "../assets/img/logo.png";
import { LoopCircleLoading } from "react-loadingg";
import {
  FormGroup,
  Form,
  Input,
  Col,
  Button,
  Container,
  FormFeedback,
} from "reactstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
// const jwt = require("jsonwebtoken");

const Register: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailUnique, setIsEmailUnique] = useState(true);
  const [isNameUnique, setIsNameUnique] = useState(true);

  const countries = useMemo(() => countryList().getData(), []);

  const [publisher, setPublisher] = useState<any>({
    publisher_company_name: "",
    publisher_name: "",
    publisher_lastname: "",
    publisher_contact_email: "",
    publisher_password: "",
    publisher_country: "",
  });

  const redirectToDashboard = () => {
    // let decoded = jwt.decode(localStorage.getItem("token"));
    // const isAdmin = decoded.is_admin;
    document.location.href = "/admin/dashboard";
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      redirectToDashboard();
    }
  }, []);

  useEffect(() => {
    (async function () {
      try {
        // let ip = await axios.get("https://ipinfo.io/json", {
        //   headers: {
        //     Authorization: `Bearer 7222099375052b`,
        //   },
        // });
        let ip = await axios.get(
          "https://ipapi.co/json/?access_key=12f091be8f09f428aaa4eb991f300204"
        );
        if (ip && ip.data && ip.data.country_code) {
          let country = countries.filter(
            (c: any) => c.value === ip.data.country_code
          );
          changePublisher("publisher_country", country);
        }
      } catch (e: any) {}
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkIsValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher]);

  const checkIsValid = () => {
    let valid = true;
    for (let key in publisher) {
      if (publisher[key] == null || publisher[key] === "") {
        valid = false;
      }
    }

    if (!validateEmail(publisher.publisher_contact_email)) {
      valid = false;
    }

    setIsValid(valid);
  };
  const register = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (publisher.publisher_country.value) {
      publisher.publisher_country = publisher.publisher_country.value;
    }
    let res = await $axios.post("/auth/register", { publisher });
    setLoading(false);
    if (res && res.data && res.data.success) {
      // const token = res.data.token;
      // localStorage.setItem("token", token);

      // let decoded = jwt.decode(token);
      // let publisher = {
      //   id: decoded.id,
      //   is_admin: decoded.is_admin,
      //   publisher_name: decoded.publisher_name,
      //   is_hours_shown: decoded.is_hours_shown,
      // };

      // dispatch({ type: "SET_TOKEN", token });
      // dispatch({ type: "SET_USER", user: publisher });
      // dispatch({
      //   type: "SET_SELECTED_PUBLISHER",
      //   publisher: publisher,
      // });
      document.location.href = "/check-your-email";

      // redirectToDashboard();
    } else {
      setErrorMessage("Error please contact us");
    }

    // console.log(res);
  };

  const checkUniqueEmail = async () => {
    if (publisher.publisher_contact_email !== "") {
      let res = await $axios.get(
        `/auth/check-email/${publisher.publisher_contact_email}`
      );
      if (res.data.data.isUnique) {
        setIsEmailUnique(true);
      } else {
        setIsEmailUnique(false);
      }
    }
    checkIsValid();
  };

  const checkPublisherName = async () => {
    if (publisher.publisher_name !== "") {
      let res = await $axios.get(
        `/auth/check-name/${publisher.publisher_name}`
      );
      if (res.data.data.isUnique) {
        setIsNameUnique(true);
      } else {
        setIsNameUnique(false);
      }
    }
  };

  const changePublisher = (field: string, val: any) => {
    let p = {
      ...publisher,
      [field]: val,
    };
    setPublisher(p);
  };
  return (
    <>
      <Container
        style={{
          position: "relative",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "550px",
          height: "100vh",
        }}
      >
        <Col
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            textAlign: "center",
            background: `linear-gradient(60deg, #6760f3 0, #8557ea 16.67%, #9c4de0 33.33%, #af42d4 50%, #bf35c7 66.67%, #cc26ba 83.33%, #d710ab 100%)`,
          }}
        >
          <Form>
            <img src={logo} alt="Logo" style={{ width: "90px" }} />
            <p className="h3 text-center" style={{ color: "white" }}>
              Register
            </p>
            <div>
              <Col>
                <FormGroup>
                  <Input
                    placeholder="Company Name"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_company_name}
                    onChange={(e) =>
                      changePublisher("publisher_company_name", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    placeholder="Contact Name"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_name}
                    onChange={(e) =>
                      changePublisher("publisher_name", e.target.value)
                    }
                    onBlur={checkPublisherName}
                    invalid={!isNameUnique}
                  />
                  {!isNameUnique && (
                    <FormFeedback tooltip>Name is already taken</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    placeholder="Last Name"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_lastname}
                    onChange={(e) =>
                      changePublisher("publisher_lastname", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    placeholder="Email"
                    type="email"
                    onBlur={checkUniqueEmail}
                    invalid={
                      (publisher.publisher_contact_email &&
                        !validateEmail(publisher.publisher_contact_email)) ||
                      !isEmailUnique
                    }
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_contact_email}
                    onChange={(e) =>
                      changePublisher("publisher_contact_email", e.target.value)
                    }
                  />
                  {publisher.publisher_contact_email &&
                    !validateEmail(publisher.publisher_contact_email) && (
                      <FormFeedback tooltip>Invalid email address</FormFeedback>
                    )}
                  {!isEmailUnique && (
                    <FormFeedback tooltip>Email already taken</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Password"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={publisher.publisher_password}
                    onChange={(e) =>
                      changePublisher("publisher_password", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Select
                    placeholder="Select country"
                    options={countries}
                    value={publisher.publisher_country}
                    onChange={(e) => {
                      changePublisher("publisher_country", e);
                    }}
                  />
                </FormGroup>
              </Col>
            </div>
            <div className="text-center">
              {errorMessage ? (
                <div style={{ color: "#3c1717" }}>{errorMessage}</div>
              ) : null}
              {!loading ? (
                <Button
                  onClick={register}
                  disabled={!isValid || !isNameUnique}
                  type="submit"
                  style={{ marginTop: "50px" }}
                >
                  Register
                </Button>
              ) : (
                <LoopCircleLoading />
              )}
            </div>
          </Form>
          <div className="mt-3">
            <Link to="signin" style={{ color: "#fff" }}>
              <i className="fas fa-arrow-left"></i> Login
            </Link>
          </div>
        </Col>
      </Container>
    </>
  );
};
export default Register;
