import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Route, Routes, Link } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { getPublishers, getCountries } from "../../actions/PublisherActions";

import routes from "routes";
import logo from "assets/img/logo.png";
import { Button } from "reactstrap";
import { exitImpersonate } from "utils/heplers";
import { decodeToken } from "react-jwt";

const Admin = (props: any) => {
  const user = useSelector((state) => (state as any).userReducer.user);

  const [backgroundColor] = useState("blue");
  const [sidebarOpened, setSidebarOpened] = useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  const dispatch = useDispatch();
  useEffect(() => {
    (async function () {
      const typeUser = user.publisher_is_admin ? "admin" : "user";
      if (typeUser === "admin") {
        await dispatch(getPublishers() as any);
      }
      await dispatch(getCountries() as any);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isImpersonate = () => {
    return localStorage.getItem("impersonate") !== null;
  };

  const dataImpersonate = () => {
    let token = localStorage.getItem("impersonate");
    return decodeToken(token);
  };

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpened(!sidebarOpened);
  };

  const canViewRoute = (route: any) => {
    const typeUser = user.publisher_is_admin ? "admin" : "user";
    if (route.hasOwnProperty("permissions")) {
      if (!route.permissions.includes(typeUser)) {
        return false;
      }

      if (route.hasOwnProperty("meta")) {
        if (route.meta.hasOwnProperty("hideToIds")) {
          if (route.meta.hideToIds.includes(user.row_id)) {
            return false;
          }
        }

        if (route.meta.hasOwnProperty("only_publishers_ids")) {
          return route.meta.only_publishers_ids.includes(user.row_id);
        }
        if (route.meta.hasOwnProperty("only_publishers_statues")) {
          return route.meta.only_publishers_statues.includes(
            user.publisher_status
          );
        }
      }
    }
    return true;
  };

  const getRoutes = (routes: any) => {
    
    return routes.map((prop: any, key: number) => {
      
      if (!canViewRoute(prop)) {
        return false;
      }
      if (prop.layout === "/admin") {
        const Rr = prop.component
        

        return (

          <Route
            path={prop.path}
            element={<Rr />}
          // name={prop.name}
          // key={key}
          // exact
          />
        );
      } else {
        return null;
      }
    });
  };

  const logout = () => {
    localStorage.clear();
    document.location.href = "/signin";
  };

  const showDropdown = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  };
  return (
    <>
      <div className="wrapper" style={{ top: isImpersonate() ? "87px" : "0" }}>
        <section>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "0.99925rem",
              padding: "10px",
            }}
          >
            <div
              style={{
                color: "white",
                fontFamily: "Poppins",
                marginLeft: "30px",
              }}
            >
              {/* Welcome! */}
            </div>

            {user.publisher_name ? (
              <div className="dropdown">
                <button onClick={showDropdown} className="dropbtn">
                  {user.publisher_name} ↓
                </button>
                <div
                  id="myDropdown"
                  className="dropdown-content"
                  style={{ zIndex: 9999 }}
                >
                  <Link className="simple-text " to="/admin/user-profile">
                    My Profile <i className="fas fa-user-alt"></i>
                  </Link>
                  <button className="logout" onClick={logout}>
                    Logout <i className="fas fa-sign-out-alt"></i>
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="float-right"
                style={{
                  padding: "0.25rem 0.75rem",
                  lineHeight: "1",
                  backgroundColor: "transparent",
                  border: "0.0625rem solid transparent",
                  color: "#27293D",
                  // fontWeight: "500",
                  cursor: "pointer",
                  marginRight: "30px",
                }}
                onClick={logout}
              >
                Logout
              </button>
            )}
          </div>
          <Sidebar
            {...props}
            routes={routes.filter((r) => canViewRoute(r))}
            bgColor={backgroundColor}
            logo={{
              outterLink: "https://reports.ninjatech.io/",
              text: "NinjaTech",
              imgSrc: logo,
            }}
            toggleSidebar={toggleSidebar}
          />
        </section>
        <div
          className="main-panel"
        // ref="mainPanel"
        // data={'backgroundColor'}
        >
          {isImpersonate() && (
            <div
              className="alert alert-danger"
              style={{
                position: "fixed",
                width: "100%",
                zIndex: 9999,
                top: 0,
              }}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  Impersonate :
                  <strong> {(dataImpersonate() as any).publisher_name}</strong>
                </div>
                <div>
                  <Button className="mr-auto" onClick={() => exitImpersonate()}>
                    Return to your user
                  </Button>
                </div>
              </div>
            </div>
          )}
          <AdminNavbar
            {...props}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
            <Routes>{getRoutes(routes)}</Routes>

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Admin;
