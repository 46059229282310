
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import { createBrowserHistory } from "history";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { Provider } from "react-redux";

import AdminLayout from "layouts/Admin/Admin";
import SignIn from "views/SignIn";
import Register from "views/Register"
import Verify from "views/Verify";
import CheckYourEmail from "views/CheckYourEmail";

import "assets/scss/black-dashboard-react.scss";
// import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));

const hist = createBrowserHistory();
// const store = configureStore();
root.render(
  <Provider store={store}>
    <Router history={hist}>
      <Routes>
        <>
          <Route path="/admin/*" element={<AdminLayout  />} />
          <Route exact path="/signin" element={<SignIn />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/verify/:id" element={<Verify />} />
          <Route exact path="/check-your-email" element={<CheckYourEmail />} />
          <Route exact path="/" element={<SignIn />} />
          {/* <Route element={<NotFound />} /> */}
        </>
      </Routes>
      <ToastContainer />
    </Router>
  </Provider>
);
