import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PublisherService } from "./../services/PublisherService";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";
import FilterWrapper from "../components/Filters/FilterWrapper";
import ChangeCountry from "components/Filters/ChangeCountry";

import { themeTable, formatNumber, paginationPerPage } from "../utils/heplers";
import SelectPublisher from "components/Global/SelectPublisher";

import { CSVLink } from "react-csv";
import Loading from "../components/Global/Loading";
import Switch from "./../components/Filters/Switch";

interface IActivity {
  publisher: string;
  active_clients: number;
  country_code: string;
}
createTheme("solarized", themeTable);

const ActiveIpsGms = () => {
  const publishers = useSelector((state) => (state as any).publisherReducer.publishers);
  const [loading, setLoading] = useState(true);
  const [showCountry, setShowCountry] = useState(false);
  const [country, setCountry] = useState("All");

  const [publisher, setPublisher] = useState<any>({});
  const [ips, setIps] = useState<any>([]);



  const changePublisher = (id: number) => {
    const p = publishers.filter((p: Publisher) => p.row_id === id)[0];

    setPublisher(p);
  };

  const getActiveIps = async () => {
    try{
      setLoading(true);

      const data = await PublisherService.loadActiveIps(
        publisher.publisher_name,
        showCountry,
        country
      );
      setIps(data.data.data);
      setLoading(false);
    }
    catch(e){
      setLoading(false);
    }
  }

  useEffect(() => {
    getActiveIps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher, country, showCountry]);

  let columns = [
    {
      name: "Publisher",
      selector: "publisher",
      sortable: true,
    },
    showCountry && {
      name: "Contry code",
      selector: "country_code",
      sortable: true,
      format: (row: IActivity) => row.country_code,
    },
    {
      name: "Active clients",
      selector: "active_clients",
      sortable: true,
      sortFunction: (a: any, b: any) => a.active_clients - b.active_clients,
      format: (row: IActivity) => formatNumber(row.active_clients),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Total IPS</div>
                    {ips && ips.length > 0 ? (
                      <CSVLink
                        filename={"Activities.csv"}
                        data={ips}
                        style={{ marginLeft: "auto" }}
                      >
                        <Button color="success" size="sm">
                          <i className="fas fa-file-csv fa-2x"></i>
                        </Button>
                      </CSVLink>
                    ) : null}
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  <div
                    className="ml-3"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SelectPublisher
                      filters="gms"
                      selected="All"
                      changed={(r: { label: string; value: number }) =>
                        changePublisher(r.value)
                      }
                    />
                    <Switch
                      isShow={showCountry}
                      onChange={(e: boolean) => setShowCountry(e)}
                      text="Country"
                    />
                    {showCountry && (
                      <ChangeCountry
                        selected={country}
                        handleChange={(e: any) => setCountry(e.target.value)}
                        style={{
                          width: "200px",
                          marginLeft: "10px",
                        }}
                      />
                    )}
                  </div>
                </FilterWrapper>
                <Loading loading={loading} />
                {ips && ips.length ? (
                  <DataTable
                    fixedHeader
                    highlightOnHover
                    columns={columns}
                    data={ips}
                    pagination={true}
                    paginationPerPage={paginationPerPage}
                    theme="solarized"
                    overflowY={true}
                    overflowYOffset="100%"
                  />
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActiveIpsGms;
