import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import persistState from "redux-localstorage";

import UserReducer from "./reducers/UserReducer";
import PublisherReducer from "./reducers/PublisherReducer";
import UsageReducer from "./reducers/UsageReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducer = combineReducers({
  userReducer: UserReducer,
  publisherReducer: PublisherReducer,
  usageReducer: UsageReducer,
});


let composeEnhancers;
if (process.env.NODE_ENV && process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose ;
} else {
  composeEnhancers = compose;
}

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk), persistState() as any)
);
export type AppState = ReturnType<typeof rootReducer>;

export default store;
