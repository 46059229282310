import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";

const ChangeCountry = (props: {
  selected: string;
  handleChange: Function;
  style?: Object;
}) => {
  const { selected, handleChange, style = {} } = props;

  const [countriesForSelect, setCountriesForSelect] = useState<any>([]);
  const countries = useSelector((state) => (state as any).publisherReducer.countries);

  useEffect(() => {
    let c = countries.filter(
      (c: { country: string; country_code: string }) => c.country_code !== "ANY"
    );
    c.unshift({ country_code: "", country: "" });
    c.unshift({ country_code: "All", country: "All" });
    c.unshift({ country_code: "ROW", country: "ROW" });
    const newCountriesForSelect = c.sort(
      (a: { country: string }, b: { country: string }) =>
        a.country.localeCompare(b.country)
    );
    setCountriesForSelect(newCountriesForSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  return (
    <div>
      {countriesForSelect.length > 0 ? (
        <Input
          className="input-white"
          type="select"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          style={style}
          value={selected ?? ""}
        >
          {countriesForSelect.map(
            (option: { country_code: string; country: string }) => (
              <option value={option.country_code} key={option.country_code}>
                {option.country}
              </option>
            )
          )}
        </Input>
      ) : null}
    </div>
  );
};

export default ChangeCountry;
