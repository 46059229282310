import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import DataTable, { createTheme } from "react-data-table-component";
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Input } from "reactstrap";
import { themeTable } from "../../utils/heplers";
import FilterWrapper from "../../components/Filters/FilterWrapper";
import Select from "react-select";
import $axios from "utils/axios";

createTheme("solarized", themeTable);

interface IactiveIpsList {
  publisher: string,
  sdk_version: string,
  active_clients: number,
}


const ActiveIps: React.FC = () => {
    const [publisherList, setPublisherList] = useState<any>([]);
    const [selectedPublisher, seTselectedPublisher] = useState({});
    const [activeIpsList, setActiveIpsList] = useState([]);

  const getData = async () => {
    try{
      const { data } = await $axios.post(`${process.env.REACT_APP_API_URL}activeips/`);
      const list = data.data.map((i: string) => {
        return {
          value: i,
          label: i
        }
      })
      setPublisherList(list);
      seTselectedPublisher(list[0]);
      const activeIps = await $axios.post(`${process.env.REACT_APP_API_URL}activeips/all`)


      setActiveIpsList(activeIps.data.data);
    }
    catch(e){
      toast.error("Can't get publisherList");
    }

  }

    useEffect(() => {
      getData()
    }, [])

    const getActiveIps = async (pub: string) => {
      try{
        const { data } = await $axios.get(`${process.env.REACT_APP_API_URL}activeips/${pub}`);
        return data.data;
      }
      catch(e){}
    }

    const sortGlobal = (a: any, b: any) => {
      a = parseFloat(a.active_clients);
      b = parseFloat(b.active_clients);
  
      if (a > b) {
        return 1;
      }
  
      if (b > a) {
        return -1;
      }
  
      return 0;
    };


    const changePublisher = async (pub: any) => {
      try{        
        const { value, label } = pub
        const activeIps = await getActiveIps(value);
        seTselectedPublisher({ value, label });
        setActiveIpsList(activeIps);
      }
      catch(e){
        toast.error("Can't get data");
      }
    };

    let columns = [
      {
        name: "Publisher",
        selector: "Publisher",
        sortable: true,
        format: (row: IactiveIpsList) => row.publisher,

      },
      {
        name: "Sdk Version",
        selector: "sdk_version",
        sortable: true,
        format: (row: any) => row.sdk_version,
      },
      {
        name: "Active Clients",
        selector: "active_clients",
        sortable: true,
        format: (row: any) => parseFloat(row.active_clients).toLocaleString(),
        sortFunction: (a: any, b: any) => sortGlobal(a,b),
      },
    ];



  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Active IPS</div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>

                  <div className="ml-3">
                  <div style={{ zIndex: 999, minWidth: "250px" }}>
                      <Select options={publisherList} value={selectedPublisher} onChange={changePublisher} />
                  </div>
                  </div>
                </FilterWrapper>

              </CardBody>
              <CardBody>
              <DataTable
                    fixedHeader
                    highlightOnHover
                    columns={columns}
                    data={activeIpsList}
                    pagination={false}
                    theme="solarized"
                    overflowY={true}
                    overflowYOffset="100%"
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ActiveIps;
