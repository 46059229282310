import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { PublisherService } from "../services/PublisherService";

import DataTable, { createTheme } from "react-data-table-component";
import Switch from "../components/Filters/Switch";
import Loading from "../components/Global/Loading";
import ChangeCountry from "../components/Filters/ChangeCountry";

import DateRange from "../components/Filters/DateRange";
import {
  lastSevenDays,
  today,
  themeTable,
  formatNumber,
  formatDate,
  paginationPerPage,
} from "../utils/heplers";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
} from "reactstrap";
import FilterWrapper from "../components/Filters/FilterWrapper";

createTheme("solarized", themeTable);

interface IPublisherUnique {
  publisher_name: String;
  daily_date: String;
  country_code: String;
  total_unique_client: number;
}

const Uniques = () => {
  const [fromDate, setFromDate] = useState(lastSevenDays());
  const [toDate, setToDate] = useState(today());
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState("");
  const [total, setTotal] = useState({ uniques: 0 });
  const [publishers, setPublishers] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [showCountries, setshowCountries] = useState(false);
  const [chosenCountry, setChosenCountry] = useState("All");

  let chosenPublisher = useSelector(
    (state) => (state as any).publisherReducer.selectedPublisher
  );

  useEffect(() => {
    (async function () {
      const res = await PublisherService.getPublisherUniques(
        fromDate,
        toDate,
        chosenPublisher,
        showCountries,
        chosenCountry
      );

      const publishers = res.data.data;
      setPublishers(publishers);
      setFilteredData(publishers);
      setLoading(false);
      calculateTotal(publishers);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenPublisher, showCountries, chosenCountry]);

  const handleDate = async (e: { start: string; end: string }) => {
    await setFromDate(e.start);
    await setToDate(e.end);
  };

  //calculateTotal
  const calculateTotal = (arr: IPublisherUnique[]) => {
    let total_unique_client = 0;
    arr.forEach((r: IPublisherUnique) => {
      total_unique_client += r.total_unique_client;
    });
    setTotal({ uniques: total_unique_client });
  };

  //search
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
  };

  useEffect(() => {
    let newFilteredData = [...publishers];
    if (!showCountries) {
      newFilteredData = newFilteredData.filter((e: IPublisherUnique) =>
        e.publisher_name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      newFilteredData = newFilteredData.filter((e: IPublisherUnique) => {
        return (
          e.country_code.toLowerCase().includes(query.toLowerCase()) ||
          e.publisher_name.toLowerCase().includes(query.toLowerCase())
        );
      });
    }
    setFilteredData(newFilteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setChosenCountry(e.target.value);
  };

  //
  let columns = [
    {
      name: "Date",
      selector: "daily_date",
      sortable: true,
      cell: (row: IPublisherUnique) => (
        <div data-tag="allowRowEvents">{formatDate(row.daily_date)}</div>
      ),
    },
    {
      name: "Publisher Name",
      selector: "publisher_name",
      sortable: true,
    },
    {
      name: `Total Uniques (${formatNumber(total.uniques)})`,
      selector: "total_unique_client",
      sortable: true,
      format: (row: IPublisherUnique) => formatNumber(row.total_unique_client),
    },
  ];

  if (showCountries) {
    columns.push({
      name: "Country Code ",
      selector: "country_code",
      sortable: true,
    });
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Unique mobile</div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  <DateRange onChange={handleDate} />
                  <Input
                    type="text"
                    placeholder="Search in Publisher, Country"
                    style={{
                      maxWidth: "350px",
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "20px",
                    }}
                    value={query}
                    onChange={handleInputChange}
                    className="search-input"
                  />
                  <div>
                    <ChangeCountry
                      selected={chosenCountry}
                      handleChange={handleChange}
                    />
                  </div>
                  <Switch
                    isShow={showCountries}
                    onChange={() => setshowCountries(!showCountries)}
                    text="Show Countries"
                  />
                </FilterWrapper>
                <Loading loading={loading} />
                {filteredData.length ? (
                  <DataTable
                    fixedHeader
                    highlightOnHover
                    columns={columns}
                    data={filteredData}
                    pagination={true}
                    paginationPerPage={paginationPerPage}
                    theme="solarized"
                    overflowY={true}
                    overflowYOffset="100%"
                  />
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default Uniques;
