import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { formatDate, formatNumber } from "utils/heplers";
export interface DataTableFormatData {
  rows: Object[];
  columns: { [id: string]: { name: string; type: string } };
}
interface CsvGeneratorPrpos {
  data: DataTableFormatData;
  setProxy: React.Dispatch<React.SetStateAction<any[]>>;
  whiteList: { colName: string; displayName: string }[];
}

const CsvGenerator = ({ data, setProxy, whiteList }: CsvGeneratorPrpos) => {
  const user = useSelector((state) => (state as any).userReducer.user);
  const [cvsExport, setCvsExport] = useState<any[]>([]);
  const [dataFormated, setDataFormated] = useState<any[]>([]);
  const onExport = () => {
    const target = JSON.stringify(
      dataFormated.map((item) => {
        return whiteList
          .map((i) => i.displayName)
          .reduce((prev: any, cur: string, idx: number) => {
            prev[cur] = typeof item[cur] === "object" ? { ...item[cur] } : item[cur];
            return prev;
          }, {});
      })
    );

    let copy = JSON.parse(target) as any[];
    setCvsExport(copy);
  };

  useEffect(() => {
    const whiteListDisplayNames = whiteList.map((i) => i.displayName);
    setDataFormated(null as any[]);
    setProxy(null as any[]);
    setCvsExport(null);

    const dataFormated = new Proxy(data.rows, {
      get: function (target: Object, prop: String | Symbol, receiver: any) {
        const elm: Object = (target as any)[prop as any] || {};
        if (!elm || typeof elm === "undefined") return Reflect.get.apply(null, arguments as any);
        if (typeof elm === "object") {
          const p = new Proxy(elm, {
            ownKeys: function (target) {
              return whiteListDisplayNames;
            },
            getOwnPropertyDescriptor: function (target, prop) {
              return { configurable: true, enumerable: true };
            },
            get: function (target: Object, prop: String | Symbol, receiver: any) {
              const r = whiteList.filter((i) => i.displayName === prop);
              if (r.length > 0) prop = r[0].colName;

              // Object.keys(data.columns).forEach((e) => {
              const isIncluded = Object.keys(data.columns).filter((key) => key === prop);

              // console.log("🚀 ~ file: index.tsx ~ line 58 ~ //Object.keys ~  Object.keys(data.columns)", data.columns, prop);
              if (isIncluded.length > 0) {
                const e = isIncluded[0];
                if (data.columns[e].type === "number") {
                  const elm: any = (target as any)[prop as any];
                  return formatNumber(elm);
                } else if (data.columns[e].type === "date") {
                  const elm: any = (target as any)[prop as any];
                  return formatDate(elm);
                } else if (data.columns[e].type === "country") {
                  const elm: any = (target as any)[prop as any];
                  return elm;
                } else if (data.columns[e].type === "money") {
                  const elm: any = (target as any)[prop as any];
                  return `$ ${formatNumber(elm, 0)}`;
                } else if (data.columns[e].type === "publisherName") {
                  if (user.publisher_is_admin === 1) {
                    const elm: any = (target as any)[prop as any];
                    return elm;
                  }
                } else if (data.columns[e].type === "country_code" && user.publisher_is_admin) {
                  const elm: any = (target as any)[prop as any];
                  return elm;
                } else if (data.columns[e].type === "pulls") {
                  if (user.publisher_is_admin === 1) {
                    const elm: any = (target as any)[prop as any];
                    return `$ ${formatNumber(elm, 0)}`;
                  }
                } else {
                  const elm: any = (target as any)[prop as any];
                  return elm;
                }
                const elm: any = (target as any)[prop as any];
                return elm;
              }
              // });
              return Reflect.get.apply(null, arguments as any);
            },
          });
          return p;
        }
        return elm;
      },
    });
    setDataFormated(dataFormated as any[]);
    setProxy(dataFormated as any[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, whiteList]);
  return (
    <>
      {cvsExport && cvsExport.length > 0 && dataFormated ? (
        <Button>
          <CSVLink data={cvsExport}>Download</CSVLink>
        </Button>
      ) : (
        <Button onClick={onExport}>CSV</Button>
      )}
    </>
  );
};

export default CsvGenerator;
