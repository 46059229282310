import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DataTable, { createTheme } from "react-data-table-component";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { themeTable } from "../../utils/heplers";
import $axios from "utils/axios";
import FilterWrapper from "components/Filters/FilterWrapper";
import Select from "react-select";
import Switch from "../../components/Filters/Switch";

createTheme("solarized", themeTable);

interface IactiveIpsList {
  publisher: string;
  sdk_version: string;
  active_clients: number;
}

type IPagination = {
    rowsPerPage: number;
    pageNum: number;
    total: number
}

const INIT = "Init";
const ALL = "All";
const ROWS_PER_PAGE = 15;


const UniqueIps: React.FC = () => {
  const [publisherList, setPublisherList] = useState<any>([]);
  const [selectedPublisher, setSelectedPublisher] = useState({ value: "", label: "" });

  const [countryCodeList, setCountryCodeList] = useState<any>([]);
  const [selectedcountryCode, setSelectedcountryCode] = useState({ value: "", label: "" });

  const [pagination, setPagination] = useState<IPagination>({ rowsPerPage: ROWS_PER_PAGE, pageNum: 1, total: 1 });

  const [uniqueIpsList, setUniqueIpsList] = useState([]);
  const [showColumns, setShowColumns] = useState({ publisher: false, country_code: false, sdk_version: false, packageName: false });

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    try {
      const { data } = await $axios.get(`${process.env.REACT_APP_API_URL}unique/init`);

      const pList = data.data.publishers.map((i: string) => {
        return { value: i, label: i };
      });

      const findAllPInit = pList.find((i: string) => i === ALL);
      if (!findAllPInit) {
        pList.unshift({ value: ALL, label: ALL });
      }

      const findPInit = pList.find((i: string) => i === INIT);
      if (!findPInit) {
        pList.unshift({ value: INIT, label: INIT });
      }

      setPublisherList(pList);

      const ccList = data.data.countries.map((i: string) => {
        return { value: i, label: i };
      });

      const findAllCCInit = ccList.find((i: string) => i === ALL);
      if (!findAllCCInit) {
        ccList.unshift({ value: ALL, label: ALL });
      }

      const findCCInit = ccList.find((i: string) => i === INIT);
      if (!findCCInit) {
        ccList.unshift({ value: INIT, label: INIT });
      }

      setCountryCodeList(ccList);

      setUniqueIpsList([data.data.agg]);

      setSelectedPublisher({ value: INIT, label: INIT });
      setSelectedcountryCode({ value: INIT, label: INIT });
    } catch (e) {
      toast.error("Can't get publisherList");
    }
  };

  const getData = async (colls: any, filters: any, paginationObj: IPagination) => {
    try {
      const payload: any = {
        agg: [],
        filter: {},
        attrs: [],
        paginationObj
      };

      if(colls.sdk_version){
        payload.attrs.push("sdk_version");
        payload.agg.push("sdk_version");
      }

      if(colls.packageName){
        payload.attrs.push("packageName");
        payload.agg.push("packageName");
      }

      if (filters.publisher !== ALL && filters.publisher !== INIT) {
        payload.filter.publisher = filters.publisher;
        payload.attrs.push("publisher");
      }

      if (filters.country_code !== ALL && filters.country_code !== INIT) {
        payload.filter.country_code = filters.country_code;
        payload.attrs.push("country_code");
      }

      if (filters.publisher === ALL) {
        payload.attrs.push("publisher");
        payload.agg.push("publisher");
      }

      if (filters.country_code === ALL) {
        payload.attrs.push("country_code");
        payload.agg.push("country_code");
      }

      const url = `${process.env.REACT_APP_API_URL}unique`;
      const { data } = await $axios.post(url, payload);

      setPagination({ ...pagination, total: data.total });
      setUniqueIpsList(data.data);

    } catch (e) {
      toast.error("Can't get publisherList");
    }
  };

  const dropDownField = async (fieldName: "country_code" | "publisher", event: any) => {
    const { value, label } = event;
    let colls = null;

    if ((fieldName === "country_code" && value === INIT && selectedPublisher.value === INIT) || (fieldName === "publisher" && value === INIT && selectedcountryCode.value === INIT)) {
      await initData();
      setShowColumns({ publisher: false, country_code: false, sdk_version: false, packageName: false });
      return;
    }

    switch (fieldName) {
      case "country_code": {
        if (value !== INIT) {
            colls = { ...showColumns, country_code: true };
        }
        else{
            colls = { ...showColumns, country_code: false };
        }
        setSelectedcountryCode({ value, label });
        const filters = { publisher: selectedPublisher.value, country_code: value };
        await getData(colls, filters, pagination);
        setShowColumns(colls);
        break;
      }

      case "publisher": {
        setSelectedPublisher({ value, label });
        if (value !== INIT) {
            colls = { ...showColumns, publisher: true };
        }
        else{
            colls = { ...showColumns, publisher: false };
        }
        const filters = { publisher: value, country_code: selectedcountryCode.value };
        await getData(colls, filters, pagination);
        setShowColumns(colls);
        break;
      }
    }
  };

  const switches = async (type: "sdk_version" | "packageName") => {
    let colls;
    const filters = { publisher: selectedPublisher.value, country_code: selectedcountryCode.value };
    switch(type){
        case 'sdk_version':{
            const sdkVersionColls = { ...showColumns, sdk_version: !showColumns.sdk_version }
            setShowColumns(sdkVersionColls);
            colls = sdkVersionColls;
            break;
        }
        case 'packageName': {
            const packageNameColls = { ...showColumns, packageName: !showColumns.packageName }
            setShowColumns(packageNameColls);
            colls = packageNameColls;
            break;
        }
    }

    await getData(colls, filters, pagination);
  };

  const sortGlobal = (a: any, b: any, type: "active_clients" | "unique_clients") => {
    a = parseFloat(a[type]);
    b = parseFloat(b[type]);

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const pageChangeHandle = async (pageNum: any) => {
    const colls = { ...showColumns };
    const filters = { publisher: selectedPublisher.value, country_code: selectedcountryCode.value };
    const paginationObj = { ...pagination, pageNum }
    setPagination(paginationObj);
    await getData(colls, filters, paginationObj);
  }

  let columns = [
    {
      name: "Publisher",
      selector: "Publisher",
      sortable: true,
      format: (row: IactiveIpsList) => row.publisher,
      omit: showColumns["publisher"] === false,
    },
    {
      name: "Country Code",
      selector: "country_code",
      sortable: true,
      format: (row: any) => row.country_code,
      omit: showColumns["country_code"] === false,
    },
    {
      name: "Sdk Version",
      selector: "sdk_version",
      sortable: true,
      format: (row: any) => row.sdk_version,
      omit: showColumns["sdk_version"] === false,
    },
    {
      name: "Package Name",
      selector: "packageName",
      sortable: true,
      format: (row: any) => row.packageName,
      omit: showColumns["packageName"] === false,
    },
    {
      name: "Unique Clients",
      selector: "unique_clients",
      sortable: true,
      format: (row: any) => parseFloat(row.unique_clients).toLocaleString(),
      sortFunction: (a: any, b: any) => sortGlobal(a, b, "unique_clients"),
    },
    {
      name: "Active Clients",
      selector: "active_clients",
      sortable: true,
      format: (row: any) => parseFloat(row.active_clients).toLocaleString(),
      sortFunction: (a: any, b: any) => sortGlobal(a, b, "active_clients"),
    },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Unique IPS</div>
                  </div>
                </CardTitle>
              </CardHeader>

              <FilterWrapper>
                <div className="ml-3">
                  <div style={{ zIndex: 999, minWidth: "250px" }}>
                    <Select options={publisherList} value={selectedPublisher} onChange={(e) => dropDownField("publisher", e)} />
                  </div>
                </div>

                <div className="ml-3">
                  <div style={{ zIndex: 999, minWidth: "250px" }}>
                    <Select options={countryCodeList} value={selectedcountryCode} onChange={(e) => dropDownField("country_code", e)} />
                  </div>
                </div>

                <Switch isShow={showColumns.packageName} onChange={() => switches("packageName")} text="Show Package Name" disabled={selectedcountryCode.value === INIT && selectedPublisher.value === INIT} />
                <Switch isShow={showColumns.sdk_version} onChange={() => switches("sdk_version")} text="Sdk Version" disabled={selectedcountryCode.value === INIT && selectedPublisher.value === INIT} />
              </FilterWrapper>

              <CardBody>
                <DataTable 
                    fixedHeader 
                    highlightOnHover 
                    columns={columns} 
                    data={uniqueIpsList} 
                    pagination
                    theme="solarized" 
                    overflowY={true} 
                    overflowYOffset="100%" 
                    paginationPerPage={ROWS_PER_PAGE} // Number of rows per page
                    onChangePage={(e) => pageChangeHandle(e)}
                    paginationTotalRows={pagination.total}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UniqueIps;
