import React from "react";
import { useSelector } from "react-redux";

const ShowCountry = (props: { country_code: string | null }) => {
  const { country_code } = props;

  const countries = useSelector((state) => (state as any).publisherReducer.countries);

  const country = countries.filter(
    (c: { country_code: string; country: string }) =>
      c.country_code && c.country_code === country_code
  );

  return (
    <>
      {country_code
        ? country.length > 0
          ? country[0].country
          : country_code
        : ""}
    </>
  );
};

export default ShowCountry;
