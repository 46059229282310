import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import FilterWrapper from "../components/Filters/FilterWrapper";
import Streamers from "components/dailyUsers/Streamers";
import Mobiles from "components/dailyUsers/Mobiles";
import Gms from "components/dailyUsers/Gms";

import DailyUsersFilters from "components/dailyUsers/DailyUsersFilters";
import { lastSevenDays, today } from "utils/heplers";
const DailyUsers = () => {
  const selectedPublisher = useSelector(
    (state) => (state as any).publisherReducer.selectedPublisher
  );

  const user = useSelector((state) => (state as any).userReducer.user);
  const [filters, setFilters] = useState<any>({
    device: "",
    publisher: "",
    showPublisher: user.publisher_is_admin ? false : true,
  });

  useEffect(() => {
    if (user.publisher_is_admin) {
      let type: string | undefined = window.location.pathname.split("/").pop();
      if (type) {
        setFilters({
          device: type,
          publisher: null,
          showPublisher: false,
          fromDate: lastSevenDays(),
          toDate: today(),
        });
      }
    } else {
      let t = selectedPublisher.publisher_is_streamer ? "streamer" : "mobile";
      let showCountry = true;
      if (selectedPublisher.is_gms) {
        t = "gms";
        showCountry = false;
      }

      setFilters({
        device: t,
        publisher: user.publisher_name,
        showPublisher: true,
        showCountry: showCountry,
        fromDate: lastSevenDays(),
        toDate: today(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Daily Users</div>
                  </div>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <FilterWrapper>
                  <DailyUsersFilters
                    isMobile={filters.device === "mobile"}
                    onFilterChange={(f: React.SetStateAction<any>) =>
                      setFilters(f)
                    }
                    filtersProps={filters}
                  />
                </FilterWrapper>
                {filters.device === "streamer" && (
                  <Streamers filters={filters} />
                )}
                {filters.device === "gms" && <Gms filters={filters} />}
                {filters.device === "mobile" && <Mobiles filters={filters} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DailyUsers;
