import React, { useEffect, useState } from "react";
import $axios from "utils/axios";
import { Form, Row, Col, FormGroup, Label, Input, Card, CardBody, ModalFooter, Button } from "reactstrap";
import { validateEmail } from "utils/heplers";
import { useDispatch } from "react-redux";
import { NewEditPublisher } from "actions/PublisherActions";
import { publisherStatus } from "enums/Publisher";
import UpdatePassword from "components/PublisherProfile/UpdatePassword";
import DatePicker, { CalendarContainer } from "react-datepicker";

const fields = {
  publisher_name: {
    type: "text",
    label: "Name",
    disabled: false,
  },
  publisher_username: {
    type: "text",
    label: "Username",
    disabled: false,
  },
  // publisher_password: {
  //   type: "password",
  //   label: "Password",
  //   disabled: true,
  // },
  publisher_contact_email: {
    type: "text",
    label: "Email",
    disabled: false,
  },
  publisher_company_name: {
    type: "text",
    label: "Company",
    disabled: false,
  },
  publisher_price: {
    type: "number",
    label: "Price",
    disabled: false,
  },
  publisher_country: {
    type: "text",
    label: "Country",
    disabled: true,
  },
  city: {
    type: "text",
    label: "City",
    disabled: false,
  },
  street: {
    type: "text",
    label: "Street",
    disabled: false,
  },
  is_hours_shown: {
    type: "select",
    label: "Is The 'Hours Column' Shown",
    values: [
      {
        value: 0,
        label: "No",
      },
      {
        value: 1,
        label: "Yes",
      },
    ],
    disabled: false,
  },
  is_email_verified: {
    type: "select",
    label: "Is The Email verified",
    values: [
      {
        value: 0,
        label: "No",
      },
      {
        value: 1,
        label: "Yes",
      },
    ],
    disabled: false,
  },
  publisher_status: {
    type: "select",
    label: "Status",
    values: [
      {
        value: publisherStatus.INACTIVE,
        label: "Inactive",
      },
      {
        value: publisherStatus.PENDING,
        label: "Pending",
      },
      {
        value: publisherStatus.ACTIVE,
        label: "Active",
      },
    ],
    disabled: false,
  },
  publisher_is_streamer: {
    type: "select",
    label: "Publisher Type",
    values: [
      {
        value: 0,
        label: "Mobile",
      },
      {
        value: 1,
        label: "Streamer",
      },
      {
        value: 2,
        label: "Streamer gms",
      },
    ],
    disabled: false,
  },
  date: {
    type: "date",
    label: "Start from date (For change price)",
    disabled: false,
  },
};

const PublisherProfile = (props) => {
  const [publisher, setPublisher] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [streamerType, setStreamerType] = useState();
  const [isEmailValid, setIsEmailValid] = useState();
  const [hoursColums, setHoursColumn] = useState();
  const [isGms, setIsGms] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let res = await $axios.get(`publishers/${props.publisherId}`);
      const { data } = res.data;
      if (data) {
        const { is_email_verified, publisher_is_streamer, is_hours_shown, is_gms } = data;
        const publisher = {
          ...data,
          is_email_verified,
          publisher_is_streamer,
          is_hours_shown,
          is_gms,
        };
        const isGmsInt = parseInt(is_gms ? 1 : 0);
        const streamerTypeInt = parseInt(publisher_is_streamer ? 1 : 0);
        setIsEmailValid(parseInt(is_email_verified ? 1 : 0));
        setHoursColumn(parseInt(is_hours_shown ? 1 : 0));
        setIsGms(isGmsInt);

        if (parseInt(publisher_is_streamer) === 0) {
          setStreamerType(0);
        } else {
          if (isGmsInt === 1 && streamerTypeInt === 1) {
            setStreamerType(2);
          } else {
            setStreamerType(1);
          }
        }
        if(data.lastPriceUpdateDate !== ''){
          setStartDate(new Date(res.data.lastPriceUpdateDate));
        }

        setPublisher(publisher);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let invalides = Object.keys(publisher).filter((field) => {
      return publisher[field] != null && publisher[field].length === "";
    });
    if (!validateEmail(publisher.publisher_contact_email) || invalides.length > 0) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [publisher]);

  const changePublisher = (field, val) => {
    let p = {
      ...publisher,
      [field]: val,
    };
    setPublisher(p);
  };

  const save = async () => {
    const d = new Date(startDate);
    const foratedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    let p = {
      ...publisher,
      is_email_verified: isEmailValid,
      publisher_is_streamer: streamerType,
      is_hours_shown: hoursColums,
      is_gms: isGms,
      date: foratedDate,
    };

    await dispatch(NewEditPublisher(p, false));
  };

  const selectHandler = (value, fieldName) => {
    switch (fieldName) {
      case "is_email_verified":
        setIsEmailValid(value);
        break;
      case "publisher_is_streamer":
        if (value === 2) {
          setIsGms(true);
        } else {
          setIsGms(false);
        }
        setStreamerType(value);

        break;
      case "is_hours_shown":
        setHoursColumn(value);
        break;
      default:
        return -1;
    }
  };

  const getSelectValue = (fieldName) => {
    switch (fieldName) {
      case "is_email_verified":
        return isEmailValid;
      case "publisher_is_streamer":
        return streamerType;
      case "is_hours_shown":
        return hoursColums;
      default:
        return -1;
    }
  };

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ backgroundColor: "#fff", color: "#000" }}>
        <CalendarContainer className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <Form className="mt-5">
              <Row>
                {Object.entries(fields).map((i, d) => (
                  <Col xs={12} md={6} key={d}>
                    <FormGroup>
                   
                      <Label for="app_names">{i[1].label}</Label>
                   
                      {i[1].type === "date" && (
                        <div>
                          <DatePicker  calendarContainer={MyContainer} className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                        </div>
                      )}
                      {(i[1].type === "text" || i[1].type === "password" || i[1].type === "number") && (
                        <Input
                          type={i[1].type}
                          value={publisher[i[0]] ? publisher[i[0]] : ""}
                          disabled={i[1].disabled}
                          className="input-white"
                          onChange={(e) => changePublisher(i[0], e.target.value)}
                          key={i[0]}
                        />
                      )}

                      {i[1].type === "select" && (
                        <Input type="select" className="input-white" value={getSelectValue(i[0])} disabled={i[1].disabled} onChange={(e) => selectHandler(parseInt(e.target.value), i[0])} key={i[0]}>
                          {i[1].values.map((v) => (
                            <option value={v.value} key={v.label}>
                              {v.label}
                            </option>
                          ))}
                        </Input>
                      )}
                    </FormGroup>
                  </Col>
                ))}
              </Row>
            </Form>
          </CardBody>
          <ModalFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button color="success" onClick={save} disabled={!isValid}>
                Save
              </Button>
            </div>
          </ModalFooter>
        </Card>
      </Col>
      <Col md="12">
        <UpdatePassword user={publisher} />
      </Col>
    </Row>
  );
};

export default PublisherProfile;
