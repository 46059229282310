import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Button, Container, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import $axios from "utils/axios";
import { ShowOnlyInstall } from "utils/config";
import styled from "styled-components";
import { isExpired, decodeToken } from "react-jwt";
import axios from "axios";

const url = `${process.env.REACT_APP_API_URL}auth/login`

const SignIn = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [user, setUser] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("impersonate")) {
      let decoded: any = decodeToken(localStorage.getItem("impersonate"));
      if (!isExpired(localStorage.getItem("impersonate"))) {
        $axios.get(`publishers/${decoded.id}`).then(async (data) => {
          await dispatch({ type: "SET_USER", user: data.data.data });
          await dispatch({
            type: "SET_SELECTED_PUBLISHER",
            publisher: data.data.data,
          });
          // setUser(data.data.data);
          // if (data.data.block === 0) {
          redirectToDashboard();
          // } else return;
        });
      }
    } else if (localStorage.getItem("token")) {
      let decoded: any = decodeToken(localStorage.getItem("token"));
      if (decoded.exp * 1000 > Date.now()) {
        // redirectToDashboard();

        $axios.get(`publishers/${decoded.id}`).then(async (data) => {
          await dispatch({ type: "SET_USER", user: data.data.data });
          await dispatch({
            type: "SET_SELECTED_PUBLISHER",
            publisher: data.data.data,
          });
          // setUser(data.data.data);
          // if (data.data.block === 0) {
          redirectToDashboard();
          // } else return;
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let valid = email !== "" && password !== "";
    setIsValid(valid);
  }, [password, email]);

  const redirectToDashboard = () => {
    let decoded : any= decodeToken(localStorage.getItem("token"));
    if (ShowOnlyInstall.includes(decoded.id)) {
      document.location.href = "/admin/installs";
    } else {
      document.location.href = "/admin/dashboard";
    }

    // const isAdmin = decoded.is_admin;
    // if (isAdmin) {
    // document.location.href = "/admin/dashboard";
    // } else {
    // document.location.href = "/admin/dashboard";
    // }
  };

  const login = async () => {
    try{
      console.log(process.env);
      setErrorMessage(null);
      const res = await axios.post(url, { email, password })
      console.log(res);
      const { success } = res.data
      if (success) {
        const { token } = res.data;
        localStorage.setItem("token", token);
  
        let decoded: any =decodeToken(token);
  
        const publishers = await $axios.get(`publishers/${decoded.id}`) as any;

        if(publishers){
          const { data } = publishers.data;
          dispatch({ type: "SET_USER", user: data });
          dispatch({ type: "SET_SELECTED_PUBLISHER", publisher: data });
  
          setUser(data);
          if (data.block === 0) {
            redirectToDashboard();
          } else {
            return;
          }
        }
      }
    }
    catch(e: any){
      setErrorMessage(e.response.data.message);
    }

  };

  return (
    <>
      <Container
        style={{
          position: "relative",
          display: "flex",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: "550px",
          height: "100vh",
        }}
      >
        <Col
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            textAlign: "center",
            background: `linear-gradient(60deg, #6760f3 0, #8557ea 16.67%, #9c4de0 33.33%, #af42d4 50%, #bf35c7 66.67%, #cc26ba 83.33%, #d710ab 100%)`,
          }}
        >
          <div>
          <img src={logo} alt="Logo" style={{ width: "90px" }} />
            <p className="h3 text-center" style={{ color: "white" }}>
              Login
            </p>
          <Col>
                <FormGroup>
                  <Input
                    placeholder=" Type your email"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Type your password"
                    style={{ backgroundColor: "white", color: "black" }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Button
                  onClick={login}
                  disabled={!isValid}
                  style={{ marginTop: "20px", marginBottom: '20px' }}
                >
                  Login
                </Button>
          </div>
          <ErrorSpan>{errorMessage}</ErrorSpan>

          <div className="mt-3">
            <Link to="register" style={{ color: "#fff" }}>
              Register <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </Col>
      </Container>
    </>
  );
};
export default SignIn;


const ErrorSpan = styled.span`
  color: white;
  margin: 100px;
  font-size: large;
`