import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllPublishers, addPublisher, deletePublisher, editPublisher } from "../actions/PublisherActions";
import { CSVLink } from "react-csv";
import DataTable, { createTheme } from "react-data-table-component";
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col, Input } from "reactstrap";
import { PublisherService } from "services/PublisherService";
import { themeTable, paginationPerPage, formatDate } from "../utils/heplers";
import AddPublisher from "../components/Publishers/AddPublisher";
import FilterWrapper from "../components/Filters/FilterWrapper";
import { Link } from "react-router-dom";
import ModalNinja from "components/Global/ModalNinja";
import PublisherName from "components/Global/PublisherName";
createTheme("solarized", themeTable);

const Publishers: React.FC = () => {
  const publishers = useSelector((state) => (state as any).publisherReducer.allpublishers);
  const [showAddPublishers, setShowPublishers] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [query, setQuery] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pass, setPass] = useState("");
  const [blocked, setBlocked] = useState<number>(0);
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (publishers && publishers.length > 0 && filteredData.length === 0) {
      setFilteredData(publishers);
    }
  }, [])
    const initPublisher = {
      publisher_name: "",
      publisher_username: "",
      publisher_password: "",
      publisher_contact_email: "",
      publisher_company_name: "",
      is_hours_shown: 1,
      publisher_price: 0,
      publisher_is_streamer: 0,
    };
  const [publisher, setPublisher] = useState(initPublisher);

  useEffect(() => {
    (function () {
      dispatch(getAllPublishers() as any);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocked]);

  useEffect(() => {
    setFilteredData(publishers);
  }, [publishers]);

  const openAddPublisher = () => {
    const newShowAddPublishers = !showAddPublishers;
    setPublisher(initPublisher);
    setShowPublishers(newShowAddPublishers);

    setEditMode(false);
  };

  const onFinish = async (p: any) => {
    await setPublisher(p);
    setShowPublishers(!showAddPublishers);
    if (editMode) {
      edit(p);
    } else {
      save(p);
    }
  };

  const save = async (p: Publisher) => {
    await dispatch(addPublisher(p) as any);
    await dispatch(getAllPublishers() as any);
    setShowPublishers(false);
  };

  // const changeShowAddMode = () => {
  //   setShowPublishers(!showAddPublishers);
  // };

  const remove = async (id: number) => {
    await dispatch(deletePublisher(id) as any);
  };

  const block = async (id: number) => {
    const newBlocked = blocked === 1 ? 0 : 1;
    setBlocked(newBlocked);
    let res = await PublisherService.blockPublisher(id, blocked);
    if (res.data.success && newBlocked === 1) {
      toast.success("The publisher has been blocked");
    }
    if (res.data.success && newBlocked === 0) {
      toast.success("The publisher has been unblocked");
    }
  };

  // const openEdit = async (row: Publisher) => {
  //   const newPass = row.publisher_password;
  //   await setPass(newPass);
  //   setEditMode(true);
  //   setPublisher(row);
  //   changeShowAddMode();
  // };

  // const onRowClick = (row: Publisher) => {
  //   setPublisher(row);
  //   changeShowAddMode();
  // };
  const edit = async (pub: Publisher) => {
    setShowPublishers(false);
    const newPass = pub.publisher_password;

    if (newPass === pass) {
      await dispatch(editPublisher(pub, 0) as any);
    } else {
      await dispatch(editPublisher(pub, 1) as any);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setQuery(e.target.value);
    const newFilteredData = publishers.filter((e: Publisher) => {
      return (
        e.publisher_name.toLowerCase().includes(query.toLowerCase()) ||
        e.publisher_username.toLowerCase().includes(query.toLowerCase()) ||
        e.publisher_company_name.toLowerCase().includes(query.toLowerCase())
      );
    });
    setFilteredData(newFilteredData);
  };

  let columns = [
    {
      name: "Publisher Name",
      selector: "publisher_name",
      sortable: true,
      grow: 1.5,
      cell: (row: any) => <PublisherName block={row.block} name={row.publisher_name} />,
    },
    // {
    //   name: "Publisher Username",
    //   selector: "publisher_username",
    //   sortable: true,
    // },
    {
      name: "Type",
      selector: "publisher_is_streamer",
      sortable: true,
      format: (row: Publisher) => (row.publisher_is_streamer === 1 ? (row.is_gms ? "Gms" : "Streamer") : "Mobile"),
    },
    {
      name: "Publisher Company",
      selector: "publisher_company_name",
      sortable: true,
    },
    {
      name: "Publisher Register Date",
      selector: "publisher_reg_date",
      sortable: true,
      format: (row: any) => formatDate(row.publisher_reg_date),
    },
    // {
    //   name: "Daily Actives",
    //   selector: "daily_actives",
    //   sortable: true,
    //   format: (row: any) => formatNumber(row.daily_actives),
    // },
    // {
    //   name: "Is Hours Shown",
    //   selector: "is_hours_shown",
    //   sortable: true,
    //   format: (row: any) => (row.is_hours_shown ? "Yes" : "No"),
    // },
    {
      name: "Publisher Price",
      selector: "publisher_price",
      sortable: true,
      format: (row: any) => row.publisher_price.toFixed(5),
    },
    {
      name: "Action",
      selector: "edit",
      grow: 2,
      // grow: 2, // if delete button
      sortable: true,
      format: (row: any) =>
        row.row_id !== 193 && (
          <div className="d-flex">
            {/* <div style={{ minWidth: 60 }}>
              <Button
                className="ml-2"
                onClick={() => openEdit(row)}
                size="sm"
                color="success"
              >
                Edit
              </Button>
            </div> */}
            <div style={{ minWidth: 60 }} className="ml-2">
              <Link to={`/admin/publisher/${row.row_id}`}>
                <Button className="mr-2" color="primary" size="sm">
                  View
                </Button>
              </Link>
            </div>
            <div style={{ minWidth: 60 }}>
              <ModalNinja buttonColor="prime" buttonLabel={row.block === 0 ? "Block" : "Unblock"} buttonSize="sm" BtnRight={row.block === 0 ? "Block" : "Unblock"} onBtnRight={() => block(row.row_id)}>
                {row.block === 0 ? "Are you sure you want to block publisher " + row.publisher_name + "?" : " Are you sure you want to unblock publisher " + row.publisher_name + "?"}
              </ModalNinja>
            </div>
            <div style={{ minWidth: 60, marginLeft: 10 }}>
              <ModalNinja buttonColor="warning" buttonLabel="Delete" buttonSize="sm" BtnRight="Delete" onBtnRight={() => remove(row.row_id)}>
                Are you sure to delete you want to delete publisher {row.publisher_name}?
              </ModalNinja>
            </div>
          </div>
        ),
    },
  ];
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>Publishers</div>
                    <Button color="success" size="sm" onClick={openAddPublisher} style={{ marginLeft: "auto" }}>
                      + Publisher
                    </Button>
                  </div>
                </CardTitle>
              </CardHeader>

              <CardBody>
                <FilterWrapper>
                  <Input
                    placeholder="Search in Name, Username, Company "
                    value={query}
                    onChange={handleInputChange}
                    style={{
                      maxWidth: "350px",
                      backgroundColor: "white",
                      color: "black",
                    }}
                  />
                </FilterWrapper>
                <Button>
                  { filteredData.length ? <CSVLink data={filteredData}>Download CSV</CSVLink> : <></>}
                </Button>
                {publishers && publishers.length ? (
                  <DataTable
                    fixedHeader
                    highlightOnHover
                    columns={columns}
                    data={filteredData}
                    // onRowClicked={onRowClick}
                    pagination={true}
                    paginationPerPage={paginationPerPage}
                    theme="solarized"
                    overflowY={true}
                    overflowYOffset="100%"
                  />
                ) : (
                  <div className="no-data">No data available</div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AddPublisher
          show={showAddPublishers}
          publisherProp={publisher}
          size="lg"
          onShowChange={(i: boolean) => {
            setShowPublishers(i);
          }}
          onFinish={(p: Publisher) => onFinish(p)}
          IsEdit={editMode}
        />
      </div>
    </>
  );
};

export default Publishers;
